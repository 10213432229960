import config from "../apiConfig";

const { API_URL } = config;

export const formatImageUrl:any = (url: any) => {
  if (url) {
    return url;
  }
  return "";
};

export function setCookie(cookieName: any, cookieValue: any) {
  document.cookie = cookieName + "=" + cookieValue + ";path=/";
}

export function getCookie(cookieName: any) {
  if (typeof window !== "undefined") {
    const nameEQ = cookieName + "=";
    const cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var c = cookieArr[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
}

export function getCookieFromCookieString(string:any, cookieName:any) {
  const nameEQ = cookieName + "="
  const cookieArr = string ? string.split(';') : ""
  for(var i=0;i < cookieArr.length;i++) {
      var c = cookieArr[i]
      while (c.charAt(0)==' ') {
          c = c.substring(1,c.length)
      }
      if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length,c.length)
      }
  }
  return null    
}

export function checkValue(value:any, not:any) {
    if(value) {
        return value
    }
    return not ? not : null
}

