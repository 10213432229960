import { usePathname } from "next/navigation";
import React, { useState } from "react";
import Select from "react-select";

const SelectLocation = (props: any) => {
  const { countryData, onDataChange, selectedCountry } = props;
  const [loading, setLoading]: any = useState(true);

  
  const path = usePathname();

  const whiteHeaderPath: any = ["/shop-now"];

  const customStyles = {
    menuList: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "transparent",
      maxHeight: "15rem",
    }),
    option: (
      styles: any,
      { data, isDisabled, isFocused, isSelected }: any
    ) => ({
      ...styles,
      "&:hover": {
        color: "#11100D",
        backgroundColor: "#D7C397",
      },
      color: isSelected ? "#F9C467" : "#D7C397",
      // color: (isFocused ? '#F9C467' : '#F4F2EA'),
      backgroundColor: isSelected
        ? "hsla(45, 13%, 6%, 0.85)"
        : "hsla(45, 13%, 6%, 0.85)",
      padding: 12,
      borderBottom: "none",
      fontFamily: "Lato",
      fontSize: "0.75rem",
      fontWeight: isSelected ? 700 : 300,
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#F9C467" : "#382003"),
      },
    }),
  };

  const whiteCustomStyles = {
    menuList: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "transparent",
      maxHeight: "15rem",
    }),
    option: (
      styles: any,
      { data, isDisabled, isFocused, isSelected }: any
    ) => ({
      ...styles,
      "&:hover": {
        color: "#11100D",
        backgroundColor: "#D7C397",
      },
      color: isSelected ? "#F9C467" : "#D7C397",
      // color: (isFocused ? '#F9C467' : '#F4F2EA'),
      backgroundColor: isSelected
        ? "hsla(45, 13%, 6%, 0.85)"
        : "hsla(45, 13%, 6%, 0.85)",
      padding: 12,
      borderBottom: "none",
      fontFamily: "Lato",
      fontSize: "0.75rem",
      fontWeight: isSelected ? 700 : 300,
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#F9C467" : "#382003"),
      },
    }),
  };
  const sortedOptions = countryData.sort((a:any, b:any) => a.label.localeCompare(b.label));
  return (
    <div className={`top-nav__search ${whiteHeaderPath.includes(path) ? "white_header_dropdown" : ""}`}>
      {loading == true && (
        <Select
          styles={whiteHeaderPath.includes(path) ?  whiteCustomStyles :  customStyles}
          className="react-select"
          placeholder={"Choose country"}
          classNamePrefix="react-select"
          options={sortedOptions}
          onChange={onDataChange}
          value={selectedCountry}
        />
      )}
    </div>
  );
};

export default SelectLocation;
