import axios from "axios";
import config from "../apiConfig";
const { API_URL, DYNAMIC_DATA } = config;
let headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
};

const Axios = axios.create({
  baseURL: API_URL,
  headers: headers,
});

export default {
  getCountryList() {
    return new Promise((resolve, reject) => {
      Axios.get(DYNAMIC_DATA.COUNTRY_LIST)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPageData(page: any, props: any) {
    return new Promise((resolve, reject) => {
      Axios.get(page, props)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  },

  getMasterTemplate(props: any) {
    return new Promise((resolve, reject) => {
      let url = props + DYNAMIC_DATA.MASTER_TEMPLATE;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getHomePage(props: any) {
    return new Promise((resolve, reject) => {
      let url = "/" + props;

      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getRarestCollectionPage(props: any) {
    return new Promise((resolve, reject) => {
      let url = props.locale + DYNAMIC_DATA.RAREST_COLLECTION;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getBlackBullCollectionPage(props: any) {
    return new Promise((resolve, reject) => {
      let url = props.locale + DYNAMIC_DATA.BLENDED_SCOTCH_WHISKY;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getProductDetailsPage(id: any, props: any) {
    return new Promise((resolve, reject) => {
      let url = props.locale + "/" + id;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAboutusPage(props: any) {
    return new Promise((resolve, reject) => {
      let url = props.locale + DYNAMIC_DATA.ABOUT_US_PAGE;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getDitilleryList() {
    return new Promise((resolve, reject) => {
      let url = DYNAMIC_DATA.DISTILLERY_LIST;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  getBrandList() {
    return new Promise((resolve, reject) => {
      let url = DYNAMIC_DATA.BRAND_LIST;
      Axios.get(url)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getStoreLocatorData(params: any) {
    return new Promise((resolve, reject) => {
      //  let url = 'https://dtstore.stageoakum.in/api/store-locator';
      let url = "https://duncantaylor-store.stageoakum.in/api/stores?pagination[limit]=-1";
      Axios.get(url, {
        params: {
          ...params,
        },
      })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getStoreBrandsData(params: any) {
    return new Promise((resolve, reject) => {
      //  let url = 'https://dtstore.stageoakum.in/api/store-locator';
      let url = "https://duncantaylor-store.stageoakum.in/api/store-brands";
      Axios.get(url, {
        params: {
          ...params,
        },
      })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
