export default {
  GOOGLE_MAPS_API_KEY: "AIzaSyCCT4hFuYzBlo2RLIYVCVIfrpSo49Oi6jk",
  API_URL: process.env.NEXT_PUBLIC_DUNCAN_TAYLOR_API_URL || "",
  storefrontAccessToken: process.env.NEXT_PUBLIC_STOREFRONTACESSTOKEN || "",
  domain: process.env.NEXT_PUBLIC_DOMAIN || "",
  appId: process.env.NEXT_PUBLIC_APPID || "",
  storefrontAccessToken_1:
    process.env.NEXT_PUBLIC_STOREFRONTACESSTOKEN_US || "",
  domain_1: process.env.NEXT_PUBLIC_DOMAIN_US || "",
  appId_1: process.env.NEXT_PUBLIC_APPID_US || "",

  DYNAMIC_DATA: {
    COUNTRY_LIST: "/api/locations?pagination%5BpageSize%5D=300",
    MASTER_TEMPLATE: "/master-template",
    RAREST_COLLECTION: "/blended-scotch-whisky/rarest-of-the-rare",
    BLENDED_SCOTCH_WHISKY: "/blended-scotch-whisky/blackbull",
    ABOUT_US_PAGE: "/about-us",
    CONTACT_US: "/api/contact-us/save",
    STORE_LOCATOR: "/api/store-locator",
    DISTILLERY_LIST: "/api/distilleries",
    BRAND_LIST: "/api/brands",
  },
};
