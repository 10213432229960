"use client";
import React, { useEffect, useState } from "react";
import SelectLocation from "../selectElement/selectLocation";
import { getCookie, setCookie } from "../../../helper/helper";
import { useLocalsListStore } from "@/zustand/localesList";
import { Image, Modal } from "react-bootstrap";

const DrinkResponsiblyModal = (props: any) => {
  const { countryData } = props;
  const [isCheckecd, setIsChecked]: any = useState(null);
  const [showYeNoError, setShowYesNoError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [selectedCountry, setSelectedCountry]: any = useState({
    label: "USA",
    value: "en-US",
  });

  let selectedCountryObj = getCookie("selectedCountry");

  const setlocalesList = useLocalsListStore(
    (state: any) => state.setlocalesList
  );

  useEffect(() => {
    const cookiesAccepted = getCookie("ageVerified");
    if (!cookiesAccepted) {
      setShowPopup(true);
      document.body.style.overflow = 'hidden';
    }
  }, []);

  const confirmAge = (event: any) => {
    setIsChecked(event.target.value);
    setShowYesNoError(false);
    if (event.target.value == "no") {
      setShow(true);
    }
  };

  const onCountrySelect = (value: any) => {
    if (value) {
      setSelectedCountry(value);
      setShowError(false);
    }
  };

  const handleSubmit = () => {
    let ageChecked = true;
    let countrySelected = true;
    if (isCheckecd == null) {
      setShowYesNoError(true);
      ageChecked = false;
    } else setShowYesNoError(false);

    if (selectedCountry == null) {
      setShowError(true);
      countrySelected = false;
    } else setShowError(false);

    if (ageChecked && countrySelected) {
      setShowError(false);
      setShowYesNoError(false);
      if (isCheckecd == "no") {
        setShow(true);
      } else {
        setShow(false);
        setCookie("ageVerified", true);
        if (!getCookie("locale")) {
          setCookie("locale", selectedCountry.value);
        }
        if (selectedCountry) {
          setCookie("selectedCountry", JSON.stringify(selectedCountry));
          const params = {
            locale: selectedCountry.value,
          };
          setlocalesList(params);
        }
        // document.body.style.overflow = 'auto';
        setShowPopup(false);
        location.reload();
      }
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const onDataChange = (data: any) => {
    if (onCountrySelect) {
      onCountrySelect(data);
    }
    if (data) {
      const newVal = { label: data.label, value: data.value };
      setSelectedCountry(newVal);
    }
  };

  useEffect(() => {
    if (selectedCountryObj) {
      setSelectedCountry(JSON.parse(selectedCountryObj));
      const params = {
        locale: JSON.parse(selectedCountryObj).value,
      };
      setlocalesList(params);
    }
  }, [selectedCountryObj]);

  return (
    showPopup && (
      <>
        <div className={`cookie-container open`}>
          <div className="cookie-card">
            <div className="top-section">
              <h3>DRINK RESPONSIBLY</h3>
              <p className="onlyDesktop">
                {/* We are committed to ensuring Duncan Taylor is enjoyed responsibly.
                <br /> */}
                Are you of legal drinking age in the country where you are right
                now?
              </p>
              <p className="onlyMobile">
                We are committed to ensuring Duncan Taylor is enjoyed responsibly.
                Are you of legal drinking age in the country where you are right
                now?
              </p>
              <div className="input-error-wrapper">
           
                <div className="age-verification-yes-no">
                  <input
                    onChange={(event) => confirmAge(event)}
                    value="yes"
                    checked={isCheckecd == "yes"}
                    type="radio"
                    id="radio-one"
                    name="switch-one"
                  />
                  <label className="new-btn-primary mx-2" htmlFor="radio-one">
                    YES
                  </label>
                  <input
                    onChange={(event) => confirmAge(event)}
                    checked={isCheckecd == "no"}
                    value="no"
                    type="radio"
                    id="radio-two"
                    name="switch-one"
                  />
                  <label className="new-btn-primary mx-2" htmlFor="radio-two">
                    NO
                  </label>
                </div>
                {showYeNoError && (
                  <label
                    className="site-input-group__error mb-2"
                    style={{ color: "#ff7675" }}
                  >
                    Please select an option.
                  </label>
                )}
              </div>
            </div>
            <div className="middle-section">
              <h3>
                By clicking on YES you are agreeing to the Cookie Policy, Terms
                & Conditions and the Privacy Policy
              </h3>
              <div className="country-dropdown">
                <SelectLocation
                  selectedCountry={selectedCountry}
                  countryData={countryData}
                  onDataChange={onDataChange}
                />
                <button className="new-btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
            <div className="lower-section">
              For all questions regarding Duncan Taylor Scotch Whisky availability and
              distribution, please contact Info@duncantaylor.com
            </div>
            {/* <img
            src="/svg/cookie-close.svg"
            className="close-btn"
            onClick={() => setShowPopup(false)}
          /> */}
          </div>
        </div>
        <Modal
          centered
          dialogClassName={`opps-modal`}
          show={show}
          onHide={handleClose}
        >
          <Modal.Header>
            <a className="modal-close" onClick={handleClose}>
              <Image alt="close" fluid src="/svg/modal-close.svg" />
            </a>
          </Modal.Header>
          <Modal.Body>
            <h3 className="section-heading-2 xs">OOPS!</h3>
            <p>Looks like you are not of legal age in your country!</p>
            <p className="mb-0">
              We are committed to ensuring Duncan Taylor is enjoyed responsibly.
            </p>
          </Modal.Body>
        </Modal>
      </>
    )
  );
};

export default DrinkResponsiblyModal;
