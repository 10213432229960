"use client";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Link from "next/link";

const Footer = (props: any) => {
  const { templateData } = props;

  return (
    <div className="footer-container">
      <Container>
        <Row>
          <Col>
            <div className="text-container">
              {templateData && (
                <Link href="/">
                  <img
                    src={
                      templateData?.footer_logo?.data?.attributes.url
                        ? templateData?.footer_logo?.data?.attributes.url
                        : ""
                    }
                    alt="img"
                  />
                </Link>
              )}

              {/* <Image
                src={
                  process.env.NEXT_PUBLIC_DUNCAN_TAYLOR_API_URL +
                  templateData?.footer_logo?.data?.attributes.url
                }
                alt="My SVG"
                width={140}
                height={125}
              /> */}
              <div className="inner-wrapper">
                <span>{templateData?.footer_title}</span>
                <span>{templateData?.footer_sub_title}</span>
              </div>

              <div className="lower-text">
                <ul>
                  {templateData?.footer_menu?.map((item: any, index: any) => {
                    return (
                      <li key={index}>
                        {item?.link ? (
                          <Link
                            href={item?.link ? item?.link : "/"}
                            target={
                              item?.open_in_new_tab == true ? "_blank" : "_self"
                            }
                          >
                            {" "}
                            {item?.title}
                          </Link>
                        ) : (
                          item?.title
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
